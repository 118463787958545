import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, breakpoints}) => ({
  sliderContainer: {
    width: '100%',
    position: 'relative',
    padding: '40px 20px',
    '& .slide': {
      [breakpoints.down('sm')]: {
        height: 150,
      },
    },
    '& .slider': {
      [breakpoints.down('sm')]: {
        height: 200,
      },
    },
  },
  partnersContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '20px',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  imageContainer: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 150,
    '& .gatsby-image-wrapper': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      marginTop: 0,
      [breakpoints.down('sm')]: {
        maxHeight: 70,
      },
    },
    [breakpoints.down('sm')]: {
      width: '50%',
      height: 100,
    },
  },

  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    textDecoration: 'none',
    width: 300,
    height: 300,
    margin: 'auto',
    padding: '20px 0',
    [breakpoints.down('sm')]: {
      width: '100%',
      height: 250,
    },
  },

  titlePartner: {
    position: 'relative',
    marginTop: '10px',
    [breakpoints.down('sm')]: {
      bottom: 0,
    },
    '& h3': {
      textDecoration: 'none',
      color: palette.text.primary,
    },
  },
  arrow: {
    cursor: 'pointer',
    color: palette.primary.main,
    fontSize: '2rem',
  },
}))

export default useStyles
