import * as R from 'ramda'
import {graphql} from 'gatsby'
import {shape} from 'prop-types'
import React from 'react'

import {
  allContentfulGroupPropTypes,
  pageContextPropTypes,
} from 'helpers/propTypes'
import CarouselPartners from 'components/Home/Partners/PartnersCarousel'
import ContentSection from 'components/UI/ContentSection'
import Description from 'components/UI/Description'
import HeroCompany from 'components/AboutUs/CompanyPurpose'
import Kpis from 'components/AboutUs/Kpis'
import MiniPictures from 'components/UI/MiniPictures'
import RoundButton from 'components/UI/RoundButton'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import Title from 'components/UI/Title'

const Group = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)

  const {
    metaTitle,
    metaDescription,
    title,
    surtitle,
    companyPurposeTitle,
    kpiTitle,
    kpiDescription: {kpiDescription},
    brandsTitle,
    brandsDescription: {brandsDescription},
    brandsCallToActionLinkedPage: {callToAction: brandsCta},
    longDescription,
    parallaxMediaType,
    video: {
      file: {url},
    },
    image,
    kpi,
    commitedKeyrus,
    brandCtaLink,
    headerCta,
    headerCtaLink,
    companyPurposeLink,
  } = R.pathOr(null, ['contentfulGroup'], data)

  const {
    callToAction,
    contentsSections1: cards,
    tooltipHumanData: {tooltipHumanData},
    tooltipInspirationalReality: {tooltipInspirationalReality},
    tooltipSimplexFuture: {tooltipSimplexFuture},
  } = R.pathOr(null, ['contentfulGroup', 'companyPurpose'], data)

  const {
    title: commitedKeyrusTitle,
    callToAction: commitedKeyrusCta,
    variation: commitedKeyrusVariation,
    description: commitedKeyrusDescription,
    image: commitedKeyrusImage,
    id: commitedKeyrusId,
    link: commitedKeyrusLink,
    typeOfLink: commitedKeyrusTypeOfLink,
  } = commitedKeyrus

  const {brands} = R.pathOr(
    null,
    ['contentfulGroup', 'brandsCallToActionLinkedPage'],
    data,
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <HeroCompany
        hat={surtitle}
        url={url}
        title={title}
        longDescription={longDescription}
        parallaxMediaType={parallaxMediaType}
        image={image}
        headerCta={headerCta}
        headerCtaLink={`//${headerCtaLink}`}
        hasCta
      />
      <Section hasSmallPadding>
        <Title>{companyPurposeTitle}</Title>
        <MiniPictures
          hasShortPath
          cards={cards}
          slug={`../../../../../${companyPurposeLink}`}
          tooltipHumanData={tooltipHumanData}
          tooltipInspirationalReality={tooltipInspirationalReality}
          tooltipSimplexFuture={tooltipSimplexFuture}
        />
        <div style={{margin: 16}}>
          <RoundButton href={companyPurposeLink} arrow isCentered isNowrap>
            {callToAction}
          </RoundButton>
        </div>
      </Section>
      <Section hasSmallPadding>
        <ContentSection
          title={commitedKeyrusTitle}
          callToAction={commitedKeyrusCta}
          variation={commitedKeyrusVariation}
          description={commitedKeyrusDescription}
          image={commitedKeyrusImage}
          id={commitedKeyrusId}
          key={commitedKeyrusId}
          link={commitedKeyrusLink}
          typeOfLink={commitedKeyrusTypeOfLink}
          countryData={countryData}
        />
      </Section>
      <Section hasSmallPadding>
        <Title>{kpiTitle}</Title>
        <Description>{kpiDescription}</Description>
        <Kpis data={kpi} />
      </Section>
      <Section hasSmallPadding>
        <Title>{brandsTitle}</Title>
        <Description>{brandsDescription}</Description>
        <CarouselPartners isBrands data={brands} countryData={countryData} />
        <RoundButton href={brandCtaLink} arrow isCentered isNowrap externalLink>
          {brandsCta}
        </RoundButton>
      </Section>
    </>
  )
}

Group.propTypes = {
  data: shape({
    allContentfulCommittedKeyrus: allContentfulGroupPropTypes,
  }).isRequired,
  pageContext: pageContextPropTypes.isRequired,
}
export default Group

export const pageQuery = graphql`
  query templateGroup(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulGroup(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      title
      surtitle
      slug
      metaTitle
      metaDescription {
        metaDescription
      }
      shortDescription {
        shortDescription
      }
      longDescription {
        raw
      }
      parallaxMediaType
      video {
        file {
          url
        }
      }
      image {
        gatsbyImageData
        file {
          url
        }
      }
      companyPurposeLink
      companyPurpose {
        callToAction
        contentsSections1 {
          image {
            file {
              url
              contentType
            }
          }
          title
          variation
          description {
            raw
          }
        }
        slug
        tooltipHumanData {
          tooltipHumanData
        }
        tooltipInspirationalReality {
          tooltipInspirationalReality
        }
        tooltipSimplexFuture {
          tooltipSimplexFuture
        }
      }
      companyPurposeTitle
      kpiTitle
      kpiDescription {
        kpiDescription
      }
      brandsTitle
      brandsDescription {
        brandsDescription
      }
      brandsCallToActionLinkedPage {
        slug
        callToAction
        brands {
          id
          website
          name
          logo {
            file {
              url
              fileName
              contentType
            }
          }
          description {
            description
          }
        }
      }
      brandCtaLink
      headerCta
      headerCtaLink
      commitedKeyrus {
        ...contentSectionFields
      }
      kpi {
        fullSentence
        icon {
          file {
            url
            contentType
          }
        }
        number
        shortText
      }
      bottomBox {
        bottomBox
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    allContentfulCountry(
      filter: {
        technicalName: {eq: $technicalName}
        node_locale: {eq: $nodeLocale}
      }
    ) {
      edges {
        node {
          id
          technicalName
          #brands {
          #  id
          #  website
          #  name
          #  logo {
          #    file {
          #      url
          #      fileName
          #      contentType
          #    }
          #  }
          #  description {
          #    description
          #  }
          #}
        }
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`
